export const UserData = [
    {
      id: 1,
      year: 2016,
      Gain: 80000,
      Lost: 823,
    },
    {
      id: 2,
      year: 2017,
      Gain: 45677,
      Lost: 345,
    },
    {
      id: 3,
      year: 2018,
      Gain: 78888,
      Lost: 555,
    },
    {
      id: 4,
      year: 2019,
      Gain: 90000,
      Lost: 4555,
    },
    {
      id: 5,
      year: 2020,
      Gain: 4300,
      Lost: 234,
    },
  ];